@font-face {
    font-family: 'National';
    src: url('/assets/National2Test-Regular.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'National-Compressed';
    src: url('/assets/National2CompressedTest-Regular.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'National';
    src: url('/assets/National2CondensedTest-Bold.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'National';
    src: url('/assets/National2CompressedTest-Extrabold.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'National';
    src: url('/assets/National2CompressedTest-Medium.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'National';
    src: url('/assets/National2CompressedTest-MediumItalic.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'National';
    src: url('/assets/National2CompressedTest-Thin.otf');
    // src: 
    // // url('/assets/fonts/29LTBukra-Bd.eot?#iefix') format('embedded-opentype'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff2') format('woff2'),
    // //     url('/assets/fonts/29LTBukra-Bd.woff') format('woff'),
    //     url('./assets/Montserrat-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


:root{
    --pageBackground: #ffffff;
    --color: #000000;
}

body, html, button{
    font-family: 'National' ;
    font-size: 2.5vw;

    @media all and (max-width:800px){
        font-size: 4vw;
    }
    @media all and (max-width:600px){
        font-size: 6.5vw;
    }
}
*{
    margin: 0;
    padding: 0;
}

a{
    cursor: pointer;
    text-decoration: none;
}

video{
    position: absolute;
    opacity: 0;
    display: none;
}

input, button{
    // display: none;
    // opacity: 0;
}


.font-extra-bold{
    font-weight: 900;
}
.font-thin{
    font-weight: 100;
}
.font-bold{
    font-weight: bold;
}
.font-medium{
    font-weight: 500;
}

.logo{
    padding: 15px 3vw 50px 3vw;
    z-index: 111111111111111111111111111111111111;
position: relative;

    a{
        display: inline-block;
        vertical-align: top;
    }

    img, svg{
        width: 15vw;
        height: auto;
        display: block;

        @media all and (max-width:600px){
            width: 30vw;

        }
    }
    @media all and (max-width:600px){
        padding-bottom: 35px;
    }
}
.title{
    width: 100%;
    h1{
      text-transform: uppercase;
      font-size: 10vw;
      line-height: 9vw;
      font-weight: 900;

      @media all and (max-width:800px){
        font-size: 15vw;
        line-height: 15vw;
    }
      @media all and (max-width:600px){
        font-size: 20vw;
        line-height: 19vw;
    }
    }
  }
  .description{
      width: 100%;
    font-size: 2vw;

    @media all and (max-width:800px){
        font-size: 3vw;
    }
    @media all and (max-width:600px){
        font-size: 4vw;
    }
  }
  .page-container{
    min-height: calc(100vh - 6vw);
    display: flex;
    align-items: flex-start;
    padding: 3vw;
    flex-flow: row wrap ;
    align-content: flex-start;
  }


.line{
    span {
        font-size: 28px;
        font-style: italic;
        margin: 0 15px;
    }
  }

  .separator {
	opacity: 0;
    padding: 0 5px;
}


.call-to-actions {
	width: 100%;
	text-align: center;
	padding-top: 50px;
	text-transform: uppercase;

  a{
    display: block;
      width: 100%;
      border: 3px solid var(--color);
      box-sizing: border-box;
      padding: 15px 10px 10px 10px;
      line-height: 2.5vw;
      margin: 20px 0;
      color: var(--color);;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      &.dark{
        background-color: var(--color) !important;
        color: #ffffff;
      }

      @media all and (max-width:800px){
        line-height: 4.5vw;
      }
  }
}

button, input, textarea{
    outline: none;
}
button:disabled {
	opacity: 0.5;
}

.grecaptcha-badge {
	opacity: 0;
	z-index: -111111;
	position: absolute;
}